import Button from '../components/base/button/Button'
import DefaultLayout from '../components/layout/DefaultLayout'
import React from 'react'

const Custom404 = () => {
    return (
        <DefaultLayout title='Stránka nenalezena / Page not found' redirect={false}>
            <div className='flex justify-center items-center min-h-screen bg-white'>
                <div className='w-full'>
                    <div className='flex flex-col items-center justify-center'>
                        <img src='/404.svg' alt='404'/>
                        <img
                            src='/404_page.svg'
                            className='ml-8 -mt-[3rem] lg:-mt-[7rem] max-w-full h-auto'
                            alt='404_page'
                        />
                    </div>
                    <h1 className='mt-10 text-4xl font-extrabold text-gray-900 sm:text-5xl text-center'>
                        Nepodařilo se nám stránku rozpoznat.
                    </h1>
                    <p className='mt-10 text-base text-gray-500 text-center mb-10'>
                        Požadovanou stránku se nám nepodařilo najít, můžete však přejít na seznam faktur.
                    </p>
                    <div className='flex justify-center'>
                        <Button as='a' href='/dashboard/invoices'>
                            Seznam faktur
                        </Button>
                    </div>
                </div>
            </div>

        </DefaultLayout>
    )

}

export default Custom404